import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import htmlDecode from "html-entities-decoder";

import Introduction from "../components/Global/Introduction"

import { GatsbyImage } from "gatsby-plugin-image";


function ProductTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    console.log(data)
    return (
        <Layout
            header={data.menu}
            services={data.services}
            productCategories={data.productCategories}
            footer={data.footer}
            metaData={constructMetaData(data.product, props.pageContext.currentPage)}
        >
            <div>
                {/* <section>
                    <Introduction backImg={data.productCategory.categoryPageH1.categoryBackgroundImage} title={htmlDecode(data.productCategory.name)} />
                </section> */}

                <section className="container m-auto px-8 md:px-4 my-16 md:my-24">
                    <div className="w-full">
                        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                            <div className="order-3 lg:order-1">
                                <div className="w-full ss-r-tab" style={{ height: "auto" }}>
                                    <div>
                                        <div className="ss-side-title">
                                            Κατηγορίες
                                    </div>
                                        <ul className="ss-nav-ul">
                                            {data.productCategories && data.productCategories.edges.map((sc, i) => (
                                                <li key={`nav-sc-${i}`}>
                                                    <Link to={`/${sc.node.slug}/`} className={props.pageContext.currentPage === sc.node.slug ? "ss-current-page" : ""}>
                                                        {htmlDecode(sc.node.name)}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="order-1 lg:order-2">
                                <div className="flex justify-center">
                                    <GatsbyImage
                                        alt={data.product.featuredImage.node.altText}
                                        loading={"lazy"}
                                        image={data.product.featuredImage.node.imageFile.childImageSharp.gatsbyImageData}
                                        formats={["auto", "webp", "avif"]}
                                        imgClassName={"cat-img"}
                                        style={{ border: "1px solid #e1e1e0", borderRadius: "4px" }}
                                        className="cursor-pointer"
                                    />
                                </div>
                            </div>
                            <div className="order-2 lg:order-3">
                                <h1 className="text-3xl w-full block pt-2 product-link">
                                    {data.product.title}
                                </h1>

                                <div className="pt-4" style={{color: "#646464"}} dangerouslySetInnerHTML={{ __html: data.product.content }} />
                            </div>
                        </div>
                    </div>
                </section>


            </div>
        </Layout>
    )
}

export default ProductTemplate;

export const pageQuery = graphql`query GET_PRODUCT($id: ID!) {
  wpgraphql {
    menu: template(id:"cG9zdDozMTIz", idType:ID){
        menuAcf{
          menu{
            menuGroup{
              url
              label
              isnested
              childnodes{
                url
                label
              }
            }
          }
        }  
    }

    product(id: $id) {
        seo {
            canonical
            title
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphImage {
                sourceUrl
            }
        }
        title
        slug
        content
        featuredImage{
            node{
                altText
                sourceUrl
                imageFile {
                    childImageSharp {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, width:368, placeholder: BLURRED)
                    }
                }
            }
        }

    }

    services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
        ...ServicesFragment
    }

    productCategories{
        edges{
            node{
                name
                slug
            }
        }
    }
    footer: template(id: "cG9zdDoyMjE5") {
        id
        title
        footerInformationTemplate {
          footerContent
        address {
          addressGoogleLink
          addressLabel
        }
        hoursLabel
        hoursTitle
        informationTitle
        supportEmail
        telephoneNumber
        servicesTitle
        generalPagesTitle
      }
    }

  }
}
`
